import React from 'react';




const Map  = () => {
    return (
            <>
      
      <div className="mapouter font-[Inter]">
        <div className="gmap_canvas"><embed width="100%" height="471" id="gmap_canvas" src="https://maps.google.com/maps?q=404,%20Rajhans%20Montessa,%20Dumas%20Road,%20Magdalla,%20Surat,%20Gujarat-395007&t=&z=13&ie=UTF8&iwloc=&output=embed" 
      frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
     </embed>
      </div>
      </div>
         
      </>
    );
  }
  
  export default Map;

