import React from 'react';
import Insight from '../components/InsigntComponents/Insight';



const InsightPage = () => {
    
    return (<>
    <Insight/>

    </>)}

export default InsightPage;